import React from 'react';

const MortagageConsiderFAQ = () => ([
    {
      title: 'What is a fixed-rate mortgage? What are the pros and cons?',
      description: (
        <>
        A fixed-rate mortgage is where the monthly repayments stay the same during the whole term of the mortgage deal.<br /><br />
        The advantage of fixed-rate mortgages is a good idea if you want the security of knowing what your monthly repayments are going to be.<br /><br />
        The disadvantage of a fixed-rate mortgage is that if interest rates fall you won't benefit from lower mortgage rates as your interest on your mortgage deal will stay the same.
        </>
      ),
    },
    {
      title: 'What are tracker mortgages? What are the pros and cons?',
      description: (
        <>
          Tracker mortgages are the type of mortgage where the interest rate can change during the course of the term following the Bank of England's base rate.<br/><br/>
          A tracker mortgage is a good idea if you predict that interest rates fall during the course of your mortgage term because as interest rates fall, so will the cost of your mortgage.<br /><br />
          The disadvantage of a tracker mortgage is that it can be difficult to budget and you don't have the financial security of knowing what your monthly repayments are going to be for the whole course of your mortgage deal. If the Bank of England's base rate changes then you are going to see changes to your mortgage monthly payments.
        </>
      ),
    },
    {
      title: 'What is a repayment mortgage?',
      description: (
        <>
          A repayment mortgage is when your monthly mortgage repayments go towards two pots, one towards paying off the amount you’ve borrowed (the capital) and the other towards paying off the interest.<br/><br/>
          Repayment mortgages are the most common type of mortgage as you're paying off the mortgage term as you go, unlike an interest-only mortgage.<br /><br />
          Fixed-rate mortgages, variable mortgages, standard variable-rate mortgages, tracker mortgages and discount mortgages are all types of repayment mortgages.
        </>
      ),
    },
    {
      title: 'What is a variable rate mortgage? What are the pros and cons?',
      description: (
        <>
          A variable-rate mortgage is where your mortgage deal interest ratecan increase or decrease during the term of your mortgage.<br/><br/>
          There are two types of variable rate mortgages, including a tracker mortgage which follows the Bank of England base rate, and a standard variable mortgage which is set out by the lender and tends to be more expensive.<br/><br/>
          The pros of a variable mortgage are that if interest rates fall then you'll benefit from your mortgage rates lowering and your mortgage monthly payments being lower.
        </>
      ),
    },
    {
      title: 'What is an interest-only mortgage? What are the pros and cons?',
      description: (
        <>
          Interest-only mortgages are where your monthly repayments are only going towards paying off the mortgage interest, and not towards the capital. So with each payment, you're not reducing the amount you owe. They tend to be offered on both fixed-rate mortgages and variable mortgages.<br/><br/>
          Although these mortgages seem more affordable, they are more expensive in the long-term as you're not chipping away the total cost of the mortgage with each monthly payment.<br/><br/>
          The reason for this type of mortgage is that you can keep your monthly cost to a minimum.<br/><br/>
          The biggest drawback of an interest-only mortgage is that you don’t pay off the loan as you go.<br/><br/>
          You will also be repaying a lot more in the total amount to your mortgage in an interest-only mortgage as you are not going to pay interest on each payment.
        </>
      ),
    },
    {
      title: 'What is a discount mortgage? What are the pros and cons?',
      description: (
        <>
          Discount mortgages are a type of variable rate mortgage where your lender gives you a discount on its standard variable rate (SVR), for a fixed time.<br/><br/>
          The pros of a discount mortgage are that you will pay a lower interest rate than your lender’s SVR for the mortgage deal term.<br/><br/>
          The disadvantage of a discount mortgage is that you lack the security of know what your monthly repayments are going to be should interest rates change.
        </>
      ),
    },
    {
      title: 'Are there early repayment charges on all types of mortgages?',
      description: (
        <>
          Not all mortgage types come with an early repayment charge, so if this is something that you're looking to avoid there is the option of finding a deal that doesn't have ERC associated.<br/><br/>
          A standard variable rate mortgage usually doesn't have early repayment charges. However, you are likely going to be on a higher interest rate for this.
        </>
      ),
    },
    {
      title: 'What type of mortgage deal is right for me?',
      description: (
        <>
          Not all mortgage types come with an early repayment charge, so if this is something that you're looking to avoid there is the option of finding a deal that doesn't have ERC associated.<br/><br/>
          Typically, if you are looking for security in knowing what your monthly payments will be, a fixed-rate mortgage may be more suitable, If you're able to take more od a risk, then a variable-rate mortgage could be more suitable.<br /><br />
          Mortgage lenders offer different types of mortgages so it's a good idea to look around to understand what is available for you.<br /><br />
          The type of mortgage depends on your personal circumstances and preferences so it's best to speak to a mortgage broker to understand what the best option is for you.
        </>
      ),
    },
  ]);

export default MortagageConsiderFAQ;
